import React from "react";
import "../shared/style/login.css";
import axios from "axios";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import { useAuth } from "../shared/HOC/authContext";
const Login = () => {
  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const { currentUser, setCurrentUser } = useAuth();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const clientId = process.env.REACT_APP_CLIENT_ID;
    setLoading(true);
    const config = {
      headers: { "client-id": clientId },
    };
    axios.post(process.env.REACT_APP_CLIENT_LOGIN_URL, formData, config).then(
      (res) => {
        localStorage.setItem("clientId", res.data.data.user.clientId);
        localStorage.setItem("role", res.data.data.user.roleName);
        localStorage.setItem("token", res.data.data.token);
        setLoading(false);
        setCurrentUser(res.data.data.user);
        history.push("/admin");
        return res;
      },
      (err) => {
        setLoading(false);
        message.error(err.response?.data?.error);
        throw err;
      }
    );
  };
  return (
    <main class="main">
      <div class="container">
        <section class="wrapper">
          <div class="heading">
            <h1 class="text text-large" style={{ textAlign: "center" }}>
              Sign In
            </h1>
          </div>
          <form onSubmit={handleSubmit} class="form">
            <div class="input-control">
              <label for="email" class="input-label" hidden>
                Email Address
              </label>
              <input
                type="email"
                name="email"
                class="input-field"
                placeholder="Email Address"
                onChange={handleChange}
                required
              />
            </div>
            <div class="input-control">
              <label for="password" class="input-label" hidden>
                Password
              </label>
              <input
                type="password"
                name="password"
                class="input-field"
                placeholder="Password"
                onChange={handleChange}
                required
              />
            </div>
            <div class="input-control">
              <button class="input-submit" disabled={loading}>
                {loading ? "loading..." : "Login"}
              </button>
            </div>
          </form>
        </section>
      </div>
    </main>
  );
};

export default Login;
