import React from "react";
import {Result} from "antd";

export default class ErrorBoundary extends React.Component {
    state = { error: null, errorInfo: null };
    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
        console.log("Error from catch", errorInfo, error);
    }

    render() {
        if (this.state.errorInfo) {
            // logout();
            return (
                <Result
                    status="500"
                    title="500"
                    subTitle="Sorry, something went wrong."
                />
            );
        }

        return this.props.children;
    }
}