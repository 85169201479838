import React, { useEffect, useState, lazy, Suspense } from "react";
import PropTypes from "prop-types";
// import qs from "query-string";
import { withRouter } from "react-router-dom";
// import { has } from "lodash";
import { Redirect, Route, Switch } from "react-router-dom";
import NavBar from "../../partials/NavBar";
import SideBar from "../../partials/SideBar";
import "../../style/default.css";
import ErrorBoundary from "../../../components/ErrorBoundary";
import { Loading } from "../../routes";
import AuthController from "../../HOC/authcontroller";
const Payments = lazy(() => import("../../../components/payments/Payments"));
const Bill = lazy(() => import("../../../components/bills/Bills"));
const User = lazy(() => import("../../../components/users/Users"));
const Service = lazy(() => import("../../../components/services/Services"));
const WalletLogs = lazy(() =>
  import("../../../components/walletLogs/WalletLogs")
);
const Providers = lazy(() => import("../../../components/providers"));

function AdminLayout(props) {
  const [activeSideBar, setActiveSideBar] = useState("payments");
  const [loading, setLoading] = useState(true);
  let [pathName, setPathName] = useState("payments");
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    pathName = props.history.location.pathname.split("/")[2];
    setPathName(pathName);
    setActiveSideBar(pathName);
    setLoading(false);
  }, [props]);

  if (loading) {
    return Loading;
  } else {
    return (
      <div className="layout-main">
        <ErrorBoundary>
          <NavBar {...props} name={pathName} />
        </ErrorBoundary>
        <ErrorBoundary>
          <SideBar {...props} activeSideBar={activeSideBar} />
        </ErrorBoundary>
        <div className={`content-main ${!props.navbar && "no-header"}`}>
          <Switch>
            <Route
              exact
              path="/admin/payments"
              component={AuthController((props) => (
                <Suspense fallback={Loading}>
                  <Payments {...props} />
                </Suspense>
              ))}
            />
            <Route
              exact
              path="/admin/bills"
              component={AuthController((props) => (
                <Suspense fallback={Loading}>
                  <Bill {...props} />
                </Suspense>
              ))}
            />

            <Route
              exact
              path="/admin/users"
              component={AuthController((props) => (
                <Suspense fallback={Loading}>
                  <User {...props} />
                </Suspense>
              ))}
            />

            <Route
              exact
              path="/admin/services"
              component={AuthController((props) => (
                <Suspense fallback={Loading}>
                  <Service {...props} />
                </Suspense>
              ))}
            />
            <Route
              exact
              path="/admin/providers"
              component={AuthController((props) => (
                <Suspense fallback={Loading}>
                  <Providers {...props} />
                </Suspense>
              ))}
            />
            <Route
              exact
              path="/admin/walletlogs"
              component={AuthController((props) => (
                <Suspense fallback={Loading}>
                  <WalletLogs {...props} />
                </Suspense>
              ))}
            />
            <Redirect path="*" exact to="/404" />
          </Switch>
        </div>
      </div>
    );
  }
}

AdminLayout.defaultProps = {
  navbar: true,
  activeSideBar: "",
};

AdminLayout.propTypes = {
  navbar: PropTypes.bool,
  activeSideBar: PropTypes.string,
};

export default withRouter(AdminLayout);
