import React from "react";
import {
  USERTOKEN,
  USERDATA,
  USERACCESSCONTROL,
  LOGIN_URL,
} from "../utils/data";
import { withRouter } from "react-router-dom";
export const logout = () => {
  localStorage.removeItem(USERTOKEN);
  localStorage.removeItem(USERDATA);
  localStorage.removeItem(USERACCESSCONTROL);
  window.location.href = LOGIN_URL + "?logout=true";
};

function NavBar(props) {
  return (
    <div className="nav-bar">
      <div className="nav-right">
        <div
          className="heading-content flex align-c"
          style={{ padding: "0 20px" }}
        >
          <span style={{ marginBottom: "10px" }}>
            {(props.name && props.name.toUpperCase()) || ""}
          </span>

          {/*<Link to={"/admin/finance"}>*/}
          {/*  <div*/}
          {/*    onClick={() => props.history.push("/admin/finance")}*/}
          {/*    style={{ cursor: "pointer" }}*/}
          {/*  >*/}
          {/*    /!*<span style={{ marginBottom: "10px" }}>EARNINGS</span>*!/*/}
          {/*    /!*<div className="font-size-small">*!/*/}
          {/*    /!*  <span style={{ color: secondaryColor }}>Total:</span>{" "}*!/*/}
          {/*    /!*  <strong style={{ color: secondaryColor }}>*!/*/}
          {/*    /!*    &#8358;*!/*/}
          {/*    /!*    {earnings.fetching ? (*!/*/}
          {/*    /!*      <Spinner color={secondaryColor} />*!/*/}
          {/*    /!*    ) : earnings.content.length < 1 ? (*!/*/}
          {/*    /!*      0.0*!/*/}
          {/*    /!*    ) : (*!/*/}
          {/*    /!*      formatCurrency(earnings.content.balance) || 0*!/*/}
          {/*    /!*    )}*!/*/}
          {/*    /!*  </strong>*!/*/}
          {/*    /!*  &nbsp;&nbsp;&nbsp;*!/*/}
          {/*    /!*  <span style={{ color: primaryColor }}>Payable:</span>{" "}*!/*/}
          {/*    /!*  <strong style={{ color: primaryColor }}>*!/*/}
          {/*    /!*    &#8358;*!/*/}
          {/*    /!*    {earnings.fetching ? (*!/*/}
          {/*    /!*      <Spinner color={secondaryColor} />*!/*/}
          {/*    /!*    ) : earnings.content.length < 1 ? (*!/*/}
          {/*    /!*      0.0*!/*/}
          {/*    /!*    ) : (*!/*/}
          {/*    /!*      formatCurrency(earnings.content.payable) || 0*!/*/}
          {/*    /!*    )}*!/*/}
          {/*    /!*  </strong>*!/*/}
          {/*    /!*  &nbsp;&nbsp;&nbsp;*!/*/}
          {/*    /!*  <span style={{ color: "#e67e22" }}>Promo:</span>{" "}*!/*/}
          {/*    /!*  <strong style={{ color: "#e67e22" }}>*!/*/}
          {/*    /!*    &#8358;*!/*/}
          {/*    /!*    {earnings.fetching ? (*!/*/}
          {/*    /!*      <Spinner color={"#e67e22"} />*!/*/}
          {/*    /!*    ) : earnings.content.length < 1 ? (*!/*/}
          {/*    /!*      0.0*!/*/}
          {/*    /!*    ) : (*!/*/}
          {/*    /!*      formatCurrency(earnings.content.promoBalance) || 0*!/*/}
          {/*    /!*    )}*!/*/}
          {/*    /!*  </strong>*!/*/}
          {/*    /!*</div>*!/*/}
          {/*  </div>*/}
          {/*</Link>*/}
        </div>
      </div>
      {/*<div className="nav-left">*/}
      {/*  <span*/}
      {/*    className="link"*/}
      {/*    onClick={() => {*/}
      {/*      setAudits(0);*/}
      {/*      setTimeout(() => props.history.push("/admin/audits"), 200);*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Badge count={audits}>*/}
      {/*        <BellOutlined style={{color: "#e67e22", fontSize: 20}} />*/}
      {/*    </Badge>*/}
      {/*  </span>*/}
      {/*  <DropDown*/}
      {/*    active={""}*/}
      {/*    onChange={() => null}*/}
      {/*    staticContent={true}*/}
      {/*    static={true}*/}
      {/*    dropDownWidth={"150px"}*/}
      {/*    options={[*/}
      {/*      {*/}
      {/*        content: (*/}
      {/*          <span className={"nav-item-drop "} onClick={navProfile}>*/}
      {/*            <Icon name={"user"} type={"feather"} /> Profile*/}
      {/*          </span>*/}
      {/*        )*/}
      {/*      },*/}
      {/*      {*/}
      {/*        content: (*/}
      {/*          <span*/}
      {/*            className={"nav-item-drop danger"}*/}
      {/*            onClick={() => logout()}*/}
      {/*          >*/}
      {/*            <Icon name={"logOut"} type={"feather"} /> Log out*/}
      {/*          </span>*/}
      {/*        )*/}
      {/*      }*/}
      {/*    ]}*/}
      {/*  >*/}
      {/*    {userData && (*/}
      {/*      <div className="user-profile">*/}
      {/*        <div className={"user-label"}>*/}
      {/*          {userData.role && userData.role.toLowerCase() === "admin"*/}
      {/*            ? "Admin"*/}
      {/*            : userData.email}*/}
      {/*        </div>*/}
      {/*        <div className="image-con">*/}
      {/*          <img src="" alt="" />*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    )}*/}
      {/*  </DropDown>*/}
      {/*</div>*/}
      {/*<Card className={searchDrop ? "search-drop show" : "search-drop"}>*/}
      {/*  <div className="padding-20">*/}
      {/*    <span className="close" onClick={() => setSearchDrop(false)}>*/}
      {/*      <Icon name="x" type="feather" />*/}
      {/*    </span>*/}
      {/*  </div>*/}
      {/*</Card>*/}
    </div>
  );
}

export default withRouter(NavBar);
