import React from "react";

export const Credit = ({
    color = "blue",
    size = "100%",
    className = "",
    style = {}

})=> (
    <svg
    viewBox="64 64 896 896"
    className={`svg-icon ${className || ""}`}
    style={{ width: size, ...style }}
    >
    <path 
    id="Union_12"
    data-name="Union 12"
    fill={color}
    d="M928 160H96c-17.7 0-32 14.3-32 32v160h896V192c0-17.7-14.3-32-32-32zM64 832c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V440H64v392zm579-184c0-4.4 3.6-8 8-8h165c4.4 0 8 3.6 8 8v72c0 4.4-3.6 8-8 8H651c-4.4 0-8-3.6-8-8v-72z"
    transform="translate(-6 -6)"
    />
    </svg>
)